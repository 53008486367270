* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* ===== @import font-family ====== */

@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
:root {
  /* ======== colors ========== */
  --white: #ffffff;
  --black: #000000;
  --default-nav: #292929;
  --default-footer: #3e495a;
  --light-green: #03ad53;
  --fb-btn: #4869d4;
  --google-btn: #eb4132;
  --shade-black: #09184b;
  --blackish: #333d4e;
  /* ======== hover:colors ========== */
  --fb-btn-hover: #647ed2;
  --google-btn-hover: #ea6256;
  --green-btn-hover: #026933;
  /* ======== home-wine-colors ========== */
  --light-brown: #8d7b72;
  --dark-brown: #5d4e47;
  --text-color: #818199;
  --wine-primary-color: #e5c0ae;
  /* ======== home-meat-colors ========== */
  --meat-primary-color: #ff0000;
  /* ======== home-dairy-colors ========== */
  --dairy-primary-color: #3172ec;
  --dairy-secondary-color: #ffd203;
  /* ======== home-vegetable-colors ========== */
  --veg-primary-color: #ffbb12;
  --veg-secondary-color: #4b8904;
  --veg-btn: #486a1d;
  --veg_btn--hover: #62af2f;
  /* ======== home-fertilizer-colors ========== */
  --fertilizer-primary-color: #bb5817;
  /* ======== home-fish-color ========== */
  --fish-primary-color: #004dd6;
  --fish-secondary-color: #ffc500;
  --fish-tertiary-color: #ff4f37;
  /* ======== home-nursery-color ========== */
  --nursery-primary-color: #6f9073;
  /* ======== home-grocery-color ========== */
  --grocery-primary-color: #ffa200;
  /* ===== font-family ====== */
  --mulish: "Mulish", sans-serif;
  --roboto: "Roboto", sans-serif;
  --montserrat: "Montserrat", sans-serif;
  --playfair: "Playfair Display", serif;
  --josefin: "Josefin Sans", sans-serif;
  --frank-ruhl-libre: "Frank Ruhl Libre", serif;
  --open-sans: "Open Sans", sans-serif;
  --oswald: "Oswald", sans-serif;
  --lato: "Lato", sans-serif;
}

/* ===== image-fluid ====== */

img {
  max-width: 100%;
  height: auto;
}

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

/* base font */
* {
  font-family: var(--roboto);
}
/* -- common-css -- */
a:hover {
  text-decoration: none;
}
.nursery_page--wrapper {
  overflow: hidden;
  background: #dbe3e6;
}

.nursery_banner_main--wrapper {
  /* background: url('../../images/nursery/banner-bg.png') no-repeat center; */
  background-size: cover;
  width: 100%;
  display: block;
  padding: 0rem 0rem 3.5rem;
}

@media (min-width: 768px) {
  .nursery_banner_main--wrapper {
    padding: 0rem 0rem 4.5rem;
  }
}

@media (min-width: 992px) {
  .nursery_banner_main--wrapper {
    margin-top: -11.045rem;
  }
}

@media (min-width: 1200px) {
  .nursery_banner_main--wrapper {
    padding: 0rem 0rem 7.5rem;
  }
}

/* -- nursery-banner -- */

.nursery_banner--wrapper {
  position: relative;
  padding-top: 10.3575rem;
}

.nursery_banner--wrapper::before {
  content: "";
  position: absolute;
  /* background: url('../../images/nursery/banner-sideimg1.png') no-repeat center; */
  width: 114px;
  height: 174px;
  top: 11rem;
  left: 0rem;
}

.banner-main {
  position: relative;
  padding-bottom: 3rem;
}

.banner_cols {
  margin-bottom: 1.875rem;
}

.banner_cols:last-child {
  margin-bottom: 0rem;
}

.banner_title--wrapper .banner_title {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 110%;
  letter-spacing: -0.105em;
  color: #66787f;
  padding-bottom: 0;
  text-align: center;
}
.banner_main--title {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
  line-height: 70px;
  letter-spacing: -0.105em;
  color: #c29680;
  text-align: center;
}
.banner_main--title br {
  display: none;
}
.banner_subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 180.5%;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  color: #7e959e;
  display: block;
  margin-top: 2rem;
  text-align: center;
}
.shop_now--btn {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: var(--white);
  background: var(--nursery-primary-color);
  border-radius: 40px;
  max-width: 142px;
  width: 100%;
  text-align: center;
  padding: 1.125rem;
  display: block;
  margin-top: 2.4375rem;
  transition: all 0.5s ease-in-out;
  margin-left: auto;
  margin-right: auto;
}
.shop_now--btn:hover {
  color: var(--white);
  background: #3d5040;
}
.banner_image--wrapper {
  text-align: center;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.banner_details--wrapper .banner_no {
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  line-height: 134.5%;
  letter-spacing: 0.05em;
  color: #b8c5c9;
  display: block;
}
.banner_details--wrapper .details_heaing {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 134.5%;
  letter-spacing: 0.05em;
  color: #5a675b;
  display: block;
  padding-bottom: 1.0625rem;
}
.banner_details--wrapper .details_desp {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #6c7e85;
  display: block;
}

.banner_pagination {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #6c7e85;
  max-width: 45px;
  width: 100%;
  position: absolute;
  transform: rotate(-90deg);
  left: auto;
  right: 1rem;
  bottom: 14rem;
}
.banner_pagination::after {
  content: "";
  position: absolute;
  max-width: 109px;
  width: 109px;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  top: 50%;
  transform: translate(-50%);
  right: -10.5rem;
}

.banner_pagination .swiper-pagination-current {
  color: #434e53;
}
.banner-thumbs {
  position: absolute;
  top: 15rem;
  right: 0rem;
  width: 80px;
}
.image_slide {
  width: 50px;
  height: 50px !important;
  padding: 5px;
}
.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}
@media (min-width: 576px) {
  .banner_title--wrapper {
    margin-top: 30px;
  }
}
@media (min-width: 768px) {
  .banner_main--title {
    font-size: 120px;
    line-height: 56.5%;
  }

  .banner_image--wrapper {
    max-width: 100%;
    margin-left: unset;
    margin-right: unset;
  }
  .image_slide {
    width: 80px;
    height: 80px !important;
    padding: 5px;
  }
  .banner_pagination {
    right: 0rem;
    bottom: 30rem;
  }
}

@media (min-width: 992px) {
  .nursery_banner--wrapper {
    padding-top: 13.3575rem;
  }
  .banner-thumbs {
    top: 0rem;
  }
  .banner_pagination {
    bottom: 10rem;
  }

  .image_slide .banner_thumbs--wrapper {
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  .image_slide.swiper-slide-thumb-active .banner_thumbs--wrapper {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border: 2px solid var(--nursery-primary-color);
  }
  .banner_main--title br {
    display: block;
  }
  .image_slide {
    padding: 10px;
  }
  .banner_title--wrapper {
    margin-top: 0px;
  }
  .banner_title--wrapper .banner_title,
  .banner_main--title,
  .banner_subtitle {
    text-align: left;
  }
  .shop_now--btn {
    margin-left: unset;
  }
}

@media (min-width: 1200px) {
  .banner-main {
    padding-bottom: 12.5rem;
  }
  .banner_cols {
    margin-bottom: 0rem;
  }
  .banner_pagination {
    bottom: 20rem;
  }
  .banner_subtitle {
    max-width: 256px;
  }
  .banner_details--wrapper {
    padding-top: 11rem;
  }
  .banner_main--title {
    font-size: 200px;
    line-height: 56.5%;
  }
  .banner_cols:last-child {
    padding-right: 100px;
  }
  .banner_subtitle {
    margin-top: 9.125rem;
  }
}

@media (min-width: 1680px) {
  .banner_title--wrapper .banner_title {
    font-size: 57px;
  }
  .banner_main--title {
    font-size: 256px;
  }
  .banner_details--wrapper .details_heaing {
    padding-right: 6rem;
  }
  .banner-thumbs,
  .banner_pagination {
    right: -1rem;
  }
  .banner_pagination {
    bottom: 24rem;
  }
  .banner_cols:last-child {
    padding-right: 15px;
  }
}

/* -- shop-a-plant -- */

.shop_a_plant--wrapper {
  background: #e9eef2;
  padding: 3.625rem 0rem 2.5625rem;
}

.shop_a_plant--wrapper .container,
.shop_a_plant--wrapper .shop_a_plant--col {
  padding-left: 15px;
  padding-right: 15px;
}

.shop_a_plant--wrapper .shop_a_plant--col {
  margin-bottom: 30px;
}

.shop_a_plant--wrapper .main_row .shop_a_plant--col:nth-of-type(2) {
  margin-bottom: 0;
}

.shop_a_plant--wrapper
  .main_row
  .shop_a_plant--col:nth-of-type(2)
  .shop_inner--row
  .shop_a_plant--col:nth-child(2) {
  margin-bottom: 30px;
}

.shop_a_plant--wrapper .row {
  margin-left: -15px;
  margin-right: -15px;
}

.shop_a_plant--col > div {
  border-radius: 12px;
  overflow: hidden;
}

.shop_tree_menu--wrapper {
  background: var(--white);
}

.shop_tree_menu--wrapper {
  padding: 30px;
}

.shop_plant_title--wrapper {
  display: flex;
  align-items: baseline;
  padding-bottom: 3.6875rem;
}

.shop_plant_title--wrapper .shop_title {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: bold;
  font-size: 37px;
  line-height: 45px;
  letter-spacing: -0.06em;
  color: #334b55;
  display: block;
  max-width: 122.66px;
  width: 100%;
  position: relative;
  margin-right: 20px;
}

.shop_plant_title--wrapper .shop_title::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 91px;
  background: rgba(0, 0, 0, 0.5);
  top: 0px;
  right: -10px;
}

.shop_plant_title--wrapper .make_world--title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135.69%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--black);
  display: block;
}

.make_world--title .tree_title {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: bold;
  font-size: 37px;
  line-height: 45px;
  letter-spacing: -0.06em;
  text-transform: lowercase;
  color: var(--nursery-primary-color);
  display: block;
  padding-bottom: 5px;
}

.filter_search {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #76848a;
  display: block;
  position: relative;
}

.filter_search::after {
  content: "";
  position: absolute;
  max-width: 88px;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.5);
  left: 10rem;
  bottom: 3px;
}

.checkbox_list--wrapper {
  padding-top: 3rem;
  padding-bottom: 1.25rem;
}

.checkbox_container {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.03em;
  color: #4c565b;
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 0;
}

.checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #003e39;
  border-radius: 50%;
}

.checkbox_container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox_container input:checked ~ .checkmark {
  background: var(--nursery-primary-color);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox_container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox_container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 7px;
  height: 12px;
  border: solid var(--white);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.collection_wrapper {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
  color: #a4b1b6;
}

.set_your--price {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.03em;
  color: #76848a;
  display: block;
}

.open_garden {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
  color: #4c565b;
  display: block;
  padding-top: 1.5rem;
}

.shop_plant--small {
  position: relative;
}

.shop_plant--small,
.shop_plant--mid,
.shop_plant--long,
.shop_plant--vertical {
  cursor: pointer;
}

.shop_plant--small .plant_img,
.shop_plant--mid .plant_img,
.shop_plant--long .plant_img,
.shop_plant--vertical .plant_img,
.shop_plant--long .shop_plant_img--wrapper img {
  transform: scale(1);
  transition: all ease 0.4s;
}

.shop_plant--small:hover .plant_img,
.shop_plant--mid:hover .plant_img,
.shop_plant--long:hover .plant_img,
.shop_plant--vertical:hover .plant_img,
.shop_plant--long:hover .shop_plant_img--wrapper img {
  transform: scale(1.1);
}

.plant_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heart_wrapper {
  position: absolute;
  top: 1.625rem;
  right: 1.103125rem;
  z-index: 2;
}

.shop_plant_inner--wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0rem 1.875rem 3.75rem 1.6875rem;
}

.shop_plant--title {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  color: var(--white);
  padding-bottom: 8px;
}

.shop_plant--subtitle {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(239, 239, 239, 0.6);
  display: block;
}

.shop_plant--mid {
  position: relative;
}

.shop_plant--mid .shop_plant_inner--wrapper {
  top: 0rem;
  padding: 3.375rem 1rem 0rem 3.0625rem;
}

.shop_plant--mid .shop_plant--title {
  color: #778c7d;
}

.shop_plant--mid .shop_plant--subtitle {
  color: #6c7a80;
}

.color_varient1 .shop_plant--title {
  color: #76848a;
}

.color_varient1 .shop_plant--subtitle {
  color: rgba(118, 132, 138, 0.6);
}

.shop_plant--long {
  position: relative;
  background: #cfd7d6;
}

.shop_plant_img--wrapper {
  text-align: center;
}

.shop_plat--details .shop_plant--title {
  color: #72807e;
}

.shop_plat--details .shop_plant--subtitle {
  color: #96978f;
}

.shop_plat--details {
  padding: 20px 20px 0px;
}

.shop_inner--row .shop_a_plant--col:last-child .heart_wrapper {
  left: 1rem;
  right: auto;
}

.shop_plant--vertical {
  position: relative;
}

.shop_plant--vertical .shop_plant_inner--wrapper {
  top: 0rem;
  padding: 2.6875rem 4.1875rem 0rem 3rem;
}

.shop_plant--long2 {
  background: #dddddd;
}

.shop_plant--small2 .shop_plant_inner--wrapper {
  padding-left: 6rem;
  padding-right: 0.0375rem;
}

.shop_inner--row .shop_a_plant--col {
  height: 380px;
}
.shop_plant--mid,
.shop_plant--small,
.shop_plant--long {
  height: 100%;
}

@media (min-width: 768px) {
  .shop_a_plant--wrapper {
    padding: 4.625rem 0rem 3.563rem;
  }
}

@media (min-width: 1200px) {
  .filter_search::after {
    max-width: 136px;
  }
  .shop_a_plant--col > div {
    height: 100%;
  }
  .shop_inner--row .shop_a_plant--col {
    height: 310px;
  }
}

@media (min-width: 1200px) and (max-width: 1536px) {
  .shop_plant_title--wrapper .shop_title,
  .make_world--title .tree_title {
    font-size: 30px;
    line-height: 35px;
  }
  .shop_plant_title--wrapper {
    padding-bottom: 3rem;
  }
  .filter_search {
    font-size: 18px;
    line-height: 18px;
  }
  .filter_search::after {
    max-width: 85px;
  }
  .collection_wrapper {
    font-size: 14px;
    line-height: 16px;
  }
  .checkbox_container {
    font-size: 14px;
    line-height: 24px;
  }
  .shop_tree_menu--wrapper {
    padding: 2.6875rem 1.01625rem 2.8rem 1.240625rem;
  }
  .shop_plant--title {
    font-size: 20px;
    line-height: 25px;
  }
  .shop_plant--subtitle {
    font-size: 9px;
    line-height: 11px;
  }
  .shop_plant_inner--wrapper {
    padding: 0rem 1rem 1.75rem;
  }
  .main_row .shop_a_plant--col:nth-child(1),
  .main_row .shop_a_plant--col:nth-child(3),
  .main_row .shop_a_plant--col:nth-child(5),
  .main_row .shop_a_plant--col:nth-child(7) {
    flex: 0 0 22%;
    max-width: 22%;
  }
  .main_row .shop_a_plant--col:nth-child(4) {
    flex: 0 0 16.5%;
    max-width: 16.5%;
  }
  .main_row .shop_a_plant--col:nth-child(6) {
    flex: 0 0 39.5%;
    max-width: 39.5%;
  }
  .main_row .shop_a_plant--col:nth-child(2) {
    flex: 0 0 56%;
    max-width: 56%;
  }
  .main_row .shop_a_plant--col:nth-child(3) .shop_plant--vertical > img {
    height: 100%;
  }
  .shop_inner--row .shop_a_plant--col:nth-child(1),
  .shop_inner--row .shop_a_plant--col:nth-child(3),
  .shop_inner--row .shop_a_plant--col:nth-child(5) {
    flex: 0 0 29.5%;
    max-width: 29.5%;
  }
  .shop_inner--row .shop_a_plant--col:nth-child(2) {
    flex: 0 0 41%;
    max-width: 41%;
  }
  .shop_inner--row .shop_a_plant--col:nth-child(4) {
    flex: 0 0 70.5%;
    max-width: 70.5%;
  }
  .shop_inner--row .shop_a_plant--col:nth-child(5) .shop_plant--small > img {
    height: 47vh;
  }
  .main_row .shop_a_plant--col:nth-child(5) .shop_plant--small,
  .main_row .shop_a_plant--col:nth-child(5) .shop_plant--small > img,
  .main_row .shop_a_plant--col:nth-child(6) {
    height: 100%;
  }
  .main_row .shop_a_plant--col:nth-child(7) .shop_plant--small2 > img,
  .main_row .shop_a_plant--col:nth-child(6) .shop_plant--long2 {
    height: 100%;
  }
}
@media (min-width: 1366px) {
  .shop_inner--row .shop_a_plant--col {
    height: 290px;
  }
}
@media (min-width: 1440px) {
  .main_row .shop_a_plant--col:nth-child(3) .shop_plant--vertical > img {
    height: 100%;
  }
  .main_row .shop_a_plant--col:nth-child(7) .shop_plant--small2 > img,
  .main_row .shop_a_plant--col:nth-child(6) .shop_plant--long2 {
    height: 100%;
  }
}

@media (min-width: 1680px) {
  .shop_inner--row .shop_a_plant--col {
    height: 330px !important;
  }
  .shop_plant_title--wrapper .make_world--title {
    padding-left: 20px;
  }
  .shop_plant_title--wrapper .shop_title::after {
    right: -20px;
  }
  .shop_inner--row .shop_a_plant--col:nth-child(1),
  .shop_inner--row .shop_a_plant--col:nth-child(3) {
    flex: 0 0 29.5%;
    max-width: 29.5%;
  }
  .shop_inner--row .shop_a_plant--col:nth-child(2) {
    flex: 0 0 41%;
    max-width: 41%;
  }
  .shop_inner--row .shop_a_plant--col:nth-child(4) {
    flex: 0 0 70.5%;
    max-width: 70.5%;
  }
  .shop_inner--row .shop_a_plant--col:nth-child(5) {
    flex: 0 0 29.5%;
    max-width: 29.5%;
    margin-bottom: 0;
  }
  .main_row .shop_a_plant--col:nth-child(4) .shop_plant--small,
  .main_row .shop_a_plant--col:nth-child(6) .shop_plant--long {
    height: 100%;
  }
  .shop_inner--row .shop_a_plant--col:nth-child(4) .shop_plant--long,
  .shop_inner--row .shop_a_plant--col:nth-child(5) .shop_plant--small,
  .shop_inner--row .shop_a_plant--col:nth-child(5) .shop_plant--small img {
    height: 100%;
  }
  .shop_inner--row .shop_a_plant--col:nth-child(7) .shop_plant--small,
  .shop_inner--row .shop_a_plant--col:nth-child(7) .shop_plant--small img {
    height: 100%;
  }
  .main_row .shop_a_plant--col:nth-last-of-type(4),
  .main_row .shop_a_plant--col:nth-last-of-type(3),
  .main_row .shop_a_plant--col:nth-last-of-type(2),
  .main_row .shop_a_plant--col:nth-last-of-type(1) {
    height: 250px;
  }
}

@media (min-width: 1880px) {
  .shop_a_plant--wrapper .col-xl-3 {
    flex: 0 0 22.2%;
    max-width: 22.2%;
  }
  .shop_a_plant--wrapper .col-xl-6 {
    flex: 0 0 55.6%;
    max-width: 55.6%;
  }
  .shop_a_plant--wrapper .col-xl-2 {
    flex: 0 0 15.82%;
    max-width: 15.82%;
  }
  .shop_a_plant--wrapper .col-xl-4 {
    flex: 0 0 39.78%;
    max-width: 39.78%;
  }
  .main_row .shop_a_plant--col:nth-child(1) .shop_tree_menu--wrapper,
  .main_row .shop_a_plant--col:nth-child(3) .shop_plant--vertical,
  .main_row .shop_a_plant--col:nth-child(3) .shop_plant--vertical img {
    height: 100%;
  }

  .main_row .shop_a_plant--col:nth-child(6) .shop_plant_img--wrapper {
    /* max-width: 391px; */
    width: 100%;
  }
  .main_row .shop_a_plant--col:nth-child(6) .shop_plant_img--wrapper img {
    width: 100%;
  }
  .main_row .shop_a_plant--col:nth-child(6) .shop_plant--long2 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

/* -- green-and-healthy -- */

.green_and_healthy--wrapper {
  position: relative;
}

.green_and_healthy--wrapper::before {
  content: "";
  position: absolute;
  /* background: url('../../images/nursery/banner-sideimg2.png') no-repeat center; */
  width: 181px;
  height: 328px;
  top: -13rem;
  right: 0rem;
  mix-blend-mode: multiply;
}

.green_and_healthy_inner--wrapper {
  background: var(--white);
}

.green_healthy--img {
  text-align: center;
}

.green_healthy--img img {
  width: 100%;
}

.green_and_healthy--details {
  padding: 2rem 1rem 0rem;
}

.green_healthy--info {
  padding: 1rem 1rem 2rem;
}

.green_and_healthy--details .green_since {
  font-style: normal;
  font-weight: bold;
  font-size: 14.6023px;
  line-height: 97%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #6c7e85;
  display: block;
  padding-bottom: 2.08625rem;
}

.green_healthy--title {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 97%;
  letter-spacing: -0.05em;
  color: var(--black);
  display: block;
  padding-bottom: 3.12125rem;
}

.green_line {
  position: relative;
}

.green_line::before {
  content: "";
  position: absolute;
  background: #8aab40;
  width: 52.58px;
  height: 6.47px;
  top: 0rem;
  left: 1rem;
}

.green_healthy--subtitle {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: normal;
  font-size: 15.8604px;
  line-height: 105%;
  letter-spacing: -0.05em;
  color: #7e959e;
  display: block;
}

.green_text {
  font-weight: 600;
  color: var(--nursery-primary-color);
}

.green_healthy--info {
  font-style: normal;
  font-weight: normal;
  font-size: 11.4732px;
  line-height: 180.5%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--black);
  display: block;
}

@media (min-width: 768px) {
  .green_healthy_details--box {
    display: flex;
    align-items: center;
  }
  .green_healthy--img {
    text-align: left;
  }
  .green_healthy--img img {
    width: auto;
  }
}

@media (min-width: 992px) {
  .green_and_healthy_inner--wrapper {
    display: flex;
    align-items: center;
    padding-right: 2rem;
  }
  .green_and_healthy--details,
  .green_healthy--info {
    padding: 0;
  }
  .green_healthy_details--box {
    max-width: 968px;
    width: 100%;
  }
  .green_healthy--info {
    max-width: 237px;
    width: 100%;
    padding-left: 1rem;
  }
  .green_and_healthy--details {
    margin-left: -2rem;
  }
}

@media (min-width: 1200px) {
  .green_healthy--title {
    font-size: 73.0114px;
  }
  .green_line::before {
    width: 62.58px;
    height: 11.47px;
  }
  .green_healthy--subtitle {
    font-size: 20.8604px;
  }
}

@media (min-width: 1680px) {
  .green_and_healthy_inner--wrapper {
    padding-right: 7rem;
  }
  .green_healthy_details--box {
    align-items: inherit;
  }
  .green_and_healthy--details {
    margin-top: -5rem;
    margin-left: -1rem;
  }
  .green_healthy--info {
    padding-left: 0;
  }
}

/* -- make-your-home -- */

.make_your_home--wrapper {
  position: relative;
  background: #cccdc7;
  z-index: 0;
}

.refresh_wrapper {
  position: absolute;
  max-width: 60px;
  width: 100%;
  top: -2rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 990;
}

.make_your_home--wrapper .container {
  position: relative;
}

.make_home--img1 {
  position: absolute;
  width: 150px;
  left: 0rem;
  bottom: -3rem;
}

.make_home--img2 {
  position: absolute;
  width: 200px;
  top: 0rem;
  right: 0rem;
}

.make_your_home--wrapper .row {
  padding: 12.1875rem 0rem 9rem;
}

.make_you_home--col {
  display: flex;
  justify-content: center;
}

.make_home--wrapper {
  max-width: 290px;
  width: 100%;
}

.make_home--wrapper .make_you {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 45px;
  letter-spacing: -0.075em;
  color: #96978f;
  display: block;
  position: relative;
  margin-left: 2rem;
  margin-bottom: -1.2rem;
}

.make_home--wrapper .make_you::before {
  content: "";
  position: absolute;
  background: #96978f;
  width: 51px;
  height: 5px;
  top: 0px;
  left: 0px;
}

.make_home--wrapper .home_title {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: bold;
  font-size: 105px;
  line-height: 90px;
  letter-spacing: -0.1em;
  color: var(--white);
  display: block;
}

.make_home--wrapper .more_beautiful {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #96978f;
  display: block;
  text-align: right;
  padding-right: 1.8125rem;
}

@media (min-width: 768px) {
  .make_home--wrapper {
    max-width: 478px;
  }
  .make_home--wrapper .make_you {
    font-size: 60px;
    line-height: 65px;
    margin-left: 3.3rem;
    margin-bottom: -1.9rem;
  }
  .make_home--wrapper .home_title {
    font-size: 180px;
    line-height: 150px;
  }
  .make_home--wrapper .more_beautiful {
    font-size: 25px;
    line-height: 35px;
  }
  .make_home--img1 {
    width: 235px;
  }
  .make_home--img2 {
    width: 275px;
  }
}

@media (min-width: 992px) {
  .refresh_wrapper {
    max-width: 90px;
    width: 100%;
    top: -2.7rem;
  }
  .make_home--wrapper {
    max-width: 680px;
  }
  .make_home--wrapper .make_you {
    font-size: 85px;
    line-height: 75px;
    margin-left: 4.3rem;
    margin-bottom: -2.1rem;
  }
  .make_home--wrapper .home_title {
    font-size: 250px;
    line-height: 205px;
  }
  .make_home--wrapper .more_beautiful {
    font-size: 30px;
    line-height: 40px;
  }
  .make_home--img1 {
    width: 350px;
    bottom: -7rem;
  }
  .make_home--img2 {
    width: 310px;
  }
}

@media (min-width: 1200px) {
  .make_home--wrapper {
    max-width: 844px;
  }
  .make_home--wrapper .make_you {
    font-size: 113px;
    line-height: 100px;
    margin-left: 5.3rem;
  }
  .make_home--wrapper .make_you::before {
    width: 61px;
    height: 10px;
  }
  .make_home--wrapper .home_title {
    font-size: 308px;
    line-height: 225px;
  }
  .make_home--wrapper .more_beautiful {
    font-size: 40px;
    line-height: 100px;
    padding-right: 4.8125rem;
  }
  .make_home--img1 {
    width: auto;
    left: -8rem;
    bottom: -12rem;
  }
  .make_home--img2 {
    width: auto;
    right: -9rem;
  }
}

@media (min-width: 1680px) {
  .make_home--img1 {
    left: -1rem;
  }
  .make_home--img2 {
    right: -5rem;
  }
}

@media (min-width: 1880px) {
  .make_home--img1 {
    left: 3rem;
    bottom: -13rem;
  }
  .make_home--img2 {
    right: -1rem;
    top: -1.8rem;
  }
}

/* -- subscribe -- */

.subscribe_wrapper {
  position: relative;
  z-index: 100;
  background: #86c899;
  padding: 3.5625rem 0rem 4rem;
}

.subscribe_text--wrapper {
  margin-bottom: 20px;
}

.subscribe_text--wrapper .subscribe_title {
  font-family: var(--montserrat);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.05em;
  color: var(--white);
  display: block;
  padding-bottom: 4px;
}

.subscribe_text--wrapper .subscribe_subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.28em;
  text-transform: uppercase;
  color: var(--white);
  display: block;
}

.subscribe_form--wrapper .form-control {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: var(--black);
  background: #a2dcb3;
  height: auto;
  border: 1px solid transparent;
  border-radius: 0%;
  outline: none;
  box-shadow: none;
  padding: 1rem;
}

.subscribe_form--wrapper .form-control:focus {
  border-color: 1px solid var(--black);
}

.subscribe_form--wrapper .form-control::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #76a684;
}

.btn_submit {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: var(--white);
  background: #da8e4a;
  text-align: center;
  max-width: 158px;
  width: 100%;
  display: block;
  padding: 1rem;
  margin-top: 1rem;
  transition: all 0.5s ease-in-out;
}

.btn_submit:hover {
  color: var(--white);
  background: var(--black);
}

@media (min-width: 768px) {
  .subscribe_text--wrapper {
    margin-bottom: 0px;
  }
  .subscribe_text--wrapper .subscribe_title {
    font-size: 46px;
    line-height: 56px;
  }
}

@media (min-width: 992px) {
  .subscribe_wrapper .row {
    align-items: baseline;
  }
  .subscribe_form--wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .subscribe_form--wrapper .form-control,
  .btn_submit {
    padding: 1.125rem 1.875rem;
  }
  .btn_submit {
    margin-top: 0px;
  }
  .subscribe_form--group {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .subscribe_form--group {
    margin-right: 2rem;
  }
}

/* -- container-size -- */

@media (min-width: 1200px) {
  .nursery_banner--wrapper .container {
    max-width: 1241px;
  }
  .shop_a_plant--wrapper .container {
    max-width: 1330px;
  }
  .make_your_home--wrapper .container {
    max-width: 1202px;
  }
  .subscribe_wrapper .container {
    max-width: 1138px;
  }
}

@media (min-width: 1680px) {
  .nursery_banner--wrapper .container {
    max-width: 1540px;
  }
  .shop_a_plant--wrapper .container {
    max-width: 1501px;
  }
  .green_and_healthy--wrapper .container {
    max-width: 1315px;
    width: 100%;
  }
  .make_your_home--wrapper .container {
    max-width: 1502px;
  }
}

@media (min-width: 1880px) {
  .nursery_banner--wrapper .container {
    max-width: 1640px;
  }
  .shop_a_plant--wrapper .container {
    max-width: 1701px;
  }
  .make_your_home--wrapper .container {
    max-width: 1602px;
  }
}
.widthfull {
  width: 100%;
}

.shop_plant_img--wrapper img {
  width: 100%;
  height: 434px;
  object-fit: cover;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .shop_plant--vertical{
    display: none;
  }
}