/* -- common-css -- */
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  font-family: var(--josefin);
}

.fertilizer_page--wrapper {
  background: #f0e7de;
  overflow: hidden;
}

.fertilizer_title {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 45px;
  text-align: center;
  color: #14100e;
}

.fertilizer_sideimg--wrapper {
  display: none;
}

@media (min-width: 576px) {
  .fertilizer_sideimg--wrapper {
    position: absolute;
    top: 17rem;
    left: 0rem;
    width: 100px;
    display: block;
  }
}

@media (min-width: 768px) {
  .fertilizer_title {
    font-size: 60px;
    line-height: 60px;
  }
}

@media (min-width: 1680px) {
  .fertilizer_sideimg--wrapper {
    width: auto;
  }
}

/* -- fertilizer-bannner -- */

.fertilizer_banner--wrapper {
  background: url("/public/images/fertilizer-bg.png") no-repeat center;
  background-size: cover;
  width: 100%;
  display: block;
  padding: 12.6875rem 0rem 0rem;
  position: relative;
  z-index: 998;
}

.fertilizer_banner--text .fertilizer_text {
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  line-height: 90%;
  text-transform: uppercase;
  color: #382e29;
  display: block;
}

.ferti_banner--cols:nth-child(1) .fertilizer_banner--text .fertilizer_text {
  padding-top: 0;
}

.ferti_banner--cols:nth-child(2) .fertilizer_banner--text .fertilizer_text {
  color: #271e17;
}

.banner_main--img {
  text-align: center;
}

.swiper-counter {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 22px;
  color: var(--white);
  width: 109px;
  height: 63px;
  text-align: center;
  position: absolute;
  right: -1rem;
  bottom: 10rem;
  transform: rotate(-90deg);
  z-index: 999;
}

.swiper-counter .count {
  font-size: 68px;
  line-height: 68px;
}

.fertilizer_social--links {
  display: none;
}

.banner_arrow--wrapper {
  position: absolute;
  bottom: 100px;
  right: 0;
}

.banner_arrow--wrapper .swiper-button-next,
.banner_arrow--wrapper .swiper-button-prev {
  border: 1px solid #fff;
  width: 40px;
  height: 40px;
  background: #302926;
  transition: all ease 0.3s;
}

.banner_arrow--wrapper .swiper-button-next:hover,
.banner_arrow--wrapper .swiper-button-prev:hover {
  background: var(--fertilizer-primary-color);
}

.banner_arrow--wrapper .swiper-button-prev {
  left: auto;
  right: 10px;
  margin-top: 22px;
}

.banner_arrow--wrapper .swiper-button-next:after,
.banner_arrow--wrapper .swiper-button-prev:after {
  font-size: 20px;
  color: #fff;
}

@media (min-width: 449px) {
  .fertilizer_banner--text .fertilizer_text {
    font-size: 100px;
  }

  .banner_arrow--wrapper {
    bottom: auto;
    top: 150px;
  }
}

@media (min-width: 576px) {
  .fertilizer_swiper--slide {
    padding: 10rem 0rem;
  }

  .banner_main--img {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: -6.25rem;
  }
}

@media (min-width: 768px) {
  .fertilizer_swiper--slide {
    padding-top: 15rem;
  }

  .fertilizer_banner--text .fertilizer_text {
    font-size: 160px;
  }
}

@media (min-width: 992px) {
  .fertilizer_banner--wrapper {
    height: 824px;
    padding-top: 200px;
  }

  .fertilizer_banner--text .fertilizer_text {
    font-size: 224px;
  }

  .ferti_banner--cols:nth-child(1) .fertilizer_banner--text .fertilizer_text {
    padding-top: 4.375rem;
  }

  .fertilizer_swiper--slide {
    padding-top: 0rem;
    padding-bottom: 10rem;
    height: 850px;
  }

  .banner_main--img {
    bottom: -26.25rem;
  }

  .swiper-counter {
    bottom: 42rem;
  }

  .banner_arrow--wrapper {
    top: 250px;
  }
}

@media (min-width: 1200px) {
  .ferti_banner--cols:first-child {
    padding-left: 6.3125rem;
  }

  .ferti_banner--cols:nth-child(2) {
    padding-right: 10.625rem;
  }

  .fertilizer_social--links {
    display: flex;
    align-items: center;
    position: absolute;
    top: 26rem;
    left: -10.5rem;
    transform: rotate(-90deg);
    z-index: 1001;
  }

  .fertilizer_links {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: var(--white);
    display: block;
    margin: 0px 30px;
  }

  .fertilizer_links:first-child {
    margin-left: 0;
  }

  .fertilizer_links:last-child {
    margin-right: 0;
  }

  .fertilizer_links {
    transition: ease all 0.4s;
  }

  .fertilizer_links:hover {
    color: var(--fertilizer-primary-color);
  }
}

@media (min-width: 1880px) {
  .ferti_banner--cols:first-child {
    padding-left: 11.3125rem;
  }

  .ferti_banner--cols:last-child {
    padding-right: 10.625rem;
  }
}

/* -- fertilizer-type -- */

.fertilizer_type--wrapper {
  position: relative;
  padding-top: 5.5rem;
  padding-bottom: 3.5rem;
}

.type_sideeffect1--wrapper {
  position: absolute;
  top: -7rem;
  left: 0rem;
  z-index: 0;
}

.type_sideeffect2--wrapper {
  position: absolute;
  bottom: -5rem;
  left: 0rem;
  z-index: 0;
}

.fertilizer_type--wrapper .fertilizer_sideimg--wrapper {
  top: auto;
  left: auto;
  right: 0rem;
  bottom: -2rem;
}

.fertilizer_type--wrapper .fertilizer_title {
  position: relative;
  z-index: 1;
  color: var(--black);
}

.type_of_fertilizer--wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 4.9375rem;
}

.fertilizer_type--item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.fertilizer_img--wrapper {
  position: relative;
  text-align: center;
  max-width: 300px;
  width: 100%;
}

.fertilizer_name--ecllipse {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  border-radius: 50%;
  position: absolute;
}

.fertilizer_type--details {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 154%;
  text-align: center;
  color: var(--black);
  display: block;
}

.fertilizer_type--details-tab {
  display: none;
}

.fertilizer_type--details-mobile {
  display: block;
  padding: 20px 0px 20px 0px;
}

.fertilizer_img--wrapper .fertilizer--image img {
  transition: ease all 0.9s;
}

.fertilizer_img--wrapper .fertilizer--image:hover img {
  transform: scale(1.2);
}

.fertilizer_type--item:nth-child(1) .fertilizer_name--ecllipse {
  width: 134px;
  height: 134px;
  background: #bb5817;
  filter: drop-shadow(0px 25px 46px rgba(0, 0, 0, 0.45));
  top: -25px;
  left: 0px;
}

.fertilizer_type--item:nth-child(2) .fertilizer_name--ecllipse {
  width: 154px;
  height: 154px;
  background: #66714d;
  box-shadow: 0px 29px 46px rgba(0, 0, 0, 0.34);
  bottom: 10px;
  left: 0px;
}

.fertilizer_type--item:nth-child(3) .fertilizer_name--ecllipse {
  width: 168px;
  height: 168px;
  background: #2a5a23;
  filter: drop-shadow(0px 24px 44px rgba(0, 0, 0, 0.35));
  top: -25px;
  right: 0px;
}

.fertilizer_type--item:nth-child(4) .fertilizer_name--ecllipse {
  width: 134px;
  height: 134px;
  background: #130a0a;
  filter: drop-shadow(0px 24px 39px rgba(0, 0, 0, 0.35));
  bottom: 25px;
  right: 0px;
}

.fertilizer_type--item:nth-child(5) .fertilizer_name--ecllipse {
  width: 134px;
  height: 134px;
  background: #5c9286;
  filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.25));
  top: -25px;
  left: 10px;
}

@media (min-width: 992px) {
  .type_of_fertilizer--wrapper {
    justify-content: space-between;
  }

  .fertilizer_type--wrapper {
    padding-top: 12.5rem;
  }

  .fertilizer--image-organic img {
    margin-top: -40px;
  }

  .fertilizer_type--details-organic {
    padding: 5px 0px 10px 0px;
  }
}

@media (min-width: 1200px) {
  .type_of_fertilizer--wrapper {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .fertilizer--image-organic img {
    margin-top: 0px;
  }

  .fertilizer_type--details-organic {
    padding: 20px 0px 20px 0px;
  }

  .fertilizer_type--details-tab {
    display: block;
  }

  .fertilizer_type--details-mobile {
    display: none;
  }

  .fertilizer_type--item {
    max-width: 345px;
    width: 100%;
    padding-left: 7px;
    padding-right: 7px;
    margin-bottom: 0px;
    position: relative;
  }

  .fertilizer_type--item::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 962px;
    background: #bbbbbb;
    bottom: -10rem;
    right: 0rem;
  }

  .fertilizer_type--item:last-child::after {
    display: none;
  }
}

@media (min-width: 1680px) {
  .fertilizer_type--item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* -- fertilizers-counters -- */

.fertilizers_counter--wrapper {
  /* background: url("") no-repeat center; */
  background-size: cover;
  width: 100%;
  display: block;
  z-index: 2;
  position: relative;
  padding: 11.9375rem 0rem 10.1875rem;
}

.fertilizer_counter--list {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
}

.fertilizers_counter--items {
  text-align: center;
  margin: 0rem 1rem 1.25rem;
}

.fertilizers_counter--items img {
  width: 60px;
}

.fertilizers_counter--items .spinning_data {
  font-style: normal;
  font-weight: normal;
  font-size: 31px;
  line-height: 104%;
  color: var(--white);
  display: block;
  padding: 14px 0px 1px;
}

.fertilizers_counter--items .data_info {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 104%;
  color: var(--white);
}

@media (min-width: 480px) {
  .fertilizers_counter--items {
    margin: 0rem 3rem 1.25rem;
  }
}

@media (min-width: 768px) {
  .fertilizers_counter--items {
    margin: 0rem 0rem 1.25rem;
  }

  .fertilizer_counter--list {
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .fertilizers_counter--items img {
    width: auto;
  }
}

/* -- about-fertilizer -- */

.about_fertilizer--wrapper {
  background: url("/public/images/fertilizer-bg.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  display: block;
  z-index: 2;
  position: relative;
  padding: 6.375rem 0rem 7.9375rem;
  z-index: 10;
}


.about_title {
  color: #000;
  padding-bottom: 1.875rem;
  text-align: left;
}

.about_fertilizer_inner--wrapper {
  margin-bottom: 1.875rem;
}

.about_fertilizer_inner--wrapper p {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 138%;
  color: #373737;
}

.about_fertilizer_inner--wrapper .blog_read--more {
  background: var(--white);
  color: var(--black);
  margin-top: 1.5rem;
}

.fertilizer_product--img {
  text-align: center;
}

.fertilizer_product--img img {
  width: 150px;
}

.fertlizer_clean--list {
  padding-bottom: 1.875rem;
}

.fertilizer_list--item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.fertilizer_list--item:last-child {
  margin-bottom: 0;
}

.fertilizer_item--details {
  padding-left: 0.8125rem;
}

.fertilizer_item--details .item_title {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 168%;
  color: #000;
  font-weight: 900;
}

.fertilizer_item--details p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 132%;
  color: #373737;
}

.testimonial_wrapper .fertilizer_sideimg--wrapper {
  top: 0%;
  left: auto;
  right: 20px;
}


.testimonial_sideeffect--wrapper {
  position: absolute;
  bottom: -11rem;
  left: 0;
  width: 200px;
  z-index: 0;
}

@media (min-width: 768px) {
  .about_fertilizer--wrapper {
    /* padding: 6.375rem 0rem 4.9375rem; */
  }
}

@media (min-width: 1200px) {

  .testimonial_wrapper .container,
  .testimonial_right--col,
  .testimonial_details--wrapper {
    position: relative;
  }

  .testimonial_wrapper .container::before,
  .testimonial_right--col::before,
  .testimonial_details--wrapper::before,
  .testimonial_right--col::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 1000px;
    background: #bbbbbb;
    bottom: -2rem;
  }

  .testimonial_wrapper .container::before,
  .testimonial_right--col::before {
    left: 15px;
  }

  .testimonial_right--col::after {
    right: 15px;
  }

  .testimonial_details--wrapper::before {
    left: 18rem;
  }

  .about_fertilizer_inner--wrapper {
    margin-top: 1.875rem;
    margin-bottom: 0;
  }

  .fertilizer_product--wrapper {
    display: flex;
  }

  .fertilizer_product--img {
    position: relative;
    /* top: -8rem; */
  }

  .fertilizer_product--img img {
    width: auto;
  }

  .fertlizer_clean--list {
    padding-bottom: 0;
  }

  .fertilizer_list--item {
    margin-bottom: 3.5rem;
  }

  .fertilizer_product--wrapper {
    width: 100%;
  }

  .fertilizer_product--img {
    width: 30%;
  }

  .fertlizer_clean--list {
    width: 70%;
    padding-right: 34px;
  }

  .fertlizer_clean--list .fertilizer_list--item:nth-child(2) {
    position: relative;
    left: 5rem;
  }

  .fertlizer_clean--list .fertilizer_list--item:nth-child(3) {
    position: relative;
    left: 11.136875rem;
  }

  .fertilizer_item--details p {
    padding-right: 9rem;
  }

  .testimonial_sideeffect--wrapper {
    width: 250px;
  }
}

@media (min-width: 1680px) {
  .fertilizer_item--details p {
    padding-right: 12.5rem;
  }

  .testimonial_wrapper .container::before,
  .testimonial_right--col::before,
  .testimonial_details--wrapper::before,
  .testimonial_right--col::after {
    bottom: -10rem;
  }

  .testimonial_right--col::before {
    left: 4.4rem;
  }

  .testimonial_details--wrapper::before {
    left: 21.6rem;
  }

  .testimonial_right--col::after {
    right: 8.2rem;
  }
}

@media (min-width: 1880px) {
  .fertlizer_clean--list .fertilizer_list--item:nth-child(2) {
    left: 5rem;
  }

  .fertlizer_clean--list .fertilizer_list--item:nth-child(3) {
    left: 11.136875rem;
  }
}

/* -- testimonial -- */

.testimonial_wrapper {
  position: relative;
}

.testimonial_wrapper .fertilizer_title {
  color: var(--black);
  margin-bottom: 1.875rem;
}

.author_img--wrapper {
  max-width: 81px;
  width: 100%;
}

.testimonial_author--slider {
  display: flex;
  align-items: center;
}

.testimonial_action--wrapper {
  height: 442px;
  overflow: hidden;
}

.author_details--wrapper {
  padding-left: 10px;
}

.author_name {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  color: #000;
  display: block;
  padding-bottom: 2px;
}

.author_single--details {
  margin-top: 30px;
}

.author_details--wrapper p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 134%;
  color: var(--black);
  padding-top: 5px;
}

.author_text {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 184%;
  color: var(--black);
  display: block;
  padding-left: 65px;
  padding-bottom: 20px;
  display: block;
  position: relative;
  width: 65%;
}

@media screen and (max-width: 1199px) {
  .author_text {
    width: 100%;
  }
}

.author_text::before {
  content: "";
  position: absolute;
  background: url("/public/images/brown-quote.svg") no-repeat center;
  background-size: cover;
  width: 52px;
  height: 30px;
  left: 10px;
}

.author_single--details .author_name,
.author_single--details .author_rating {
  position: relative;
  padding-left: 65px;
}

.author_single--details .author_name::before {
  content: "";
  position: absolute;
  width: 59px;
  height: 2px;
  background: #000;
  top: 40%;
  left: 0%;
}

.author_single--img {
  text-align: center;
  z-index: 50;
}

.author_slider {
  background-color: #f0e7de;
}

.author_stars:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .testimonial_wrapper {
    padding-bottom: 106px;
  }

  .testimonial_wrapper .fertilizer_title {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .testimonial_wrapper {
    padding-top: 50px;
    padding-bottom: 0px;
  }

  .author_details--slider {
    display: flex;
    align-items: center;
  }

  .author_single--img {
    max-width: 298px;
    width: 100%;
  }

  .author_single--img img {
    max-width: 298px;
  }

  .author_single--details {
    position: relative;
    margin-top: 0;
    padding-left: 32px;
  }

  .author_details--slider {
    padding: 76px 0px 186px;
  }

  /* .author_single--details::before {
    content: "";
    position: absolute;
    background: url('../../../../public/images/testimonial-border.svg') no-repeat center;
    background-size: contain;
    width: 643px;
    height: 444px;
    top: -19rem;
    left: -14rem;
  } */

  .author_text {
    padding-right: 3rem;
  }
}

@media (min-width: 1200px) and (max-width: 1536px) {
  .testimonial_wrapper .fertilizer_title {
    font-size: 53px;
  }
}

@media (min-width: 1680px) {
  .testimonial_wrapper .row {
    align-items: center;
  }

  .author_details--slider {
    padding: 83px 0px 70px;
  }

  .testimonial_details--wrapper {
    padding-left: 3.5rem;
  }

  .author_text {
    padding-right: 10rem;
  }

  .testimonial_wrapper {
    padding-bottom: 100px;
  }
}

/* -- blog -- */

.our_blog--wrapper {
  position: relative;
  background: #e5e5e5;
}

.our_blog--wrapper .container {
  position: relative;
  z-index: 1;
  padding-top: 5.0625rem;
  padding-bottom: 12rem;
}

.our_blog--wrapper .fertilizer_title {
  padding-bottom: 2.5625rem;
}

.blog_details--wrapper {
  position: relative;
  width: 100%;
  z-index: 20;
}

.blog_overlay--wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.blog_date--wrapper {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 166%;
  color: var(--fertilizer-primary-color);
  display: block;
}

.blog_title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 121%;
  color: #14100e;
  display: block;
  padding: 8px 0px 11px;
}

.blog_details--wrapper p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 166%;
  color: #14100e;
}

.blog_overlay--wrapper,
.blog_schedule--wrapper {
  padding: 2rem 1.3125rem 1.6875rem;
}

.blog_overlay--wrapper .blog_title {
  color: var(--white);
}

.blog_overlay--wrapper p {
  color: var(--white);
}

.blog_read--more {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 166%;
  color: var(--white);
  text-align: center;
  background: var(--fertilizer-primary-color);
  border-radius: 24.5px;
  display: block;
  outline: none;
  box-shadow: none;
  padding: 13px;
  max-width: 118px;
  width: 100%;
  margin-top: 1.4375rem;
  margin-bottom: 0.7rem;
  text-transform: capitalize;
  transition: all 0.5s ease-in-out;
  z-index: 20;
}

.blog_read--more:hover {
  background: #14100e;
  color: var(--white);
}

.blog_schedule--wrapper {
  background: #f6f1ec;
  width: 100%;
  position: relative;
  z-index: 999;
}

.blog_effect {
  position: absolute;
  top: 0rem;
  right: 0rem;
  z-index: 0;
}

.blog_details--wrapper img {
  width: 100%;
}

.our_blog--wrapper .container::before,
.our_blog--wrapper .container::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background: #bbbbbb;
  top: 0rem;
}

.our_blog--wrapper .container::before {
  left: 0.9375rem;
}

.our_blog--wrapper .container::after {
  right: 0.9375rem;
}

@media (min-width: 576px) {
  .blog_overlay--wrapper {
    padding: 2rem 2.3125rem 0rem;
  }
}

@media (min-width: 768px) {
  .our_blog--wrapper .fertilizer_title {
    padding-bottom: 3.5625rem;
  }

  .blog_img--wrapper {
    display: block;
  }
}

@media (min-width: 992px) {
  .blog_cols1 {
    padding-right: 0;
  }

  .blog_cols2 {
    padding-left: 0;
  }

  .blog_cols2 .blog_details--wrapper,
  .blog_cols3 .blog_details--wrapper {
    display: flex;
    height: 100%;
  }

  .blog_img--wrapper img {
    height: 43vh;
  }

  .blog_cols2 .blog_details--wrapper img {
    width: 438px;
  }
}

@media (min-width: 1200px) {
  .our_blog--wrapper .container {
    padding-bottom: 15rem;
  }

  .blog_cols2 .blog_details--wrapper,
  .blog_cols3 .blog_details--wrapper {
    display: block;
    height: 100%;
  }

  .blog_img--wrapper img {
    height: auto;
  }

  .blog_cols1,
  .blog_cols3 {
    height: 541.81px;
  }

  .blog_cols2 {
    padding-right: 0;
  }

  .blog_cols3 {
    padding-left: 0;
  }

  .blog_cols2 .blog_schedule--wrapper {
    padding: 0.9375rem 0.9375rem 1.6875rem 0.9375rem;
    z-index: 1000;
  }

  .blog_cols3 .blog_schedule--wrapper {
    padding: 1.1875rem 2.375rem 2.2rem 3.5625rem;
  }

  .blog_details--wrapper,
  .blog_cols1 .blog_details--wrapper img,
  .blog_cols3 .blog_details--wrapper img {
    height: 100%;
  }

  .blog_cols2::before,
  .blog_cols2::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 975px;
    background: #bbbbbb;
    top: -13rem;
    z-index: -1;
  }

  .blog_cols2::before {
    left: 0;
  }

  .blog_cols2::after {
    right: 0;
  }

  .blog_cols2 .blog_details--wrapper img {
    width: 100%;
  }

  .blog_cols3 .blog_img--wrapper {
    height: 50.9%;
  }

  .blog_effect {
    top: 5rem;
  }
}

@media (min-width: 1680px) {
  .our_blog--wrapper {
    margin-bottom: -2.5rem;
  }

  .blog_cols3 .blog_schedule--wrapper {
    height: 54.3%;
  }

  .blog_cols3 .blog_img--wrapper {
    height: 45.7%;
  }

  .blog_cols1,
  .blog_cols3 {
    height: 605.23px;
  }

  .blog_cols2::before,
  .blog_cols2::after {
    height: 1040px;
  }
}

@media (min-width: 1880px) {
  .blog_effect {
    right: 1rem;
  }
}

/* -- container-size -- */

@media (min-width: 1200px) {

  .fertilizer_banner--wrapper .container,
  .fertilizer_type--wrapper .container,
  .our_blog--wrapper .container,
  .our_team--wrapper .container,
  .shop_wrapper .container,
  .about_fertilizer--wrapper .container,
  .testimonial_wrapper .container {
    max-width: 1262px;
  }
}

@media (min-width: 1680px) {

  .fertilizer_banner--wrapper .container,
  .fertilizer_type--wrapper .container,
  .our_blog--wrapper .container,
  .our_team--wrapper .container,
  .shop_wrapper .container,
  .about_fertilizer--wrapper .container,
  .testimonial_wrapper .container {
    max-width: 1562px;
  }

  .fertilizers_counter--wrapper .container {
    max-width: 1287px;
  }
}

.testimonial_wrapper::before {
  content: '';
  position: absolute;
  background: url('../../../../public/images/testimonial-border.svg') no-repeat center;
  background-size: 50% 88%;
  width: 100%;
  height: 100%;
  top: 0px;
  left: -94px;
}

@media screen and (min-width: 1400px) and (max-width: 1680px) {
  .testimonial_wrapper::before {
    background-size: 62% 88%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial_wrapper::before {
    background-size: 70% 88%;
  }
}

@media screen and (max-width: 1199px) {
  .testimonial_wrapper::before {
    background: transparent;
  }
}