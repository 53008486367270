.commonPL {
    padding-left: 114px !important;
}

@media screen and (min-width: 1400px) {
    .about_fertilizer--wrapper .commonPL {
        padding-left: 0px !important;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1680px) {
    .commonPL {
        padding-left: 34px !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .commonPL {
        padding-left: 34px !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .commonPL {
        padding-left: 34px !important;
    }

    .about_fertilizer--wrapper .commonPL {
        padding-left: 0px !important;
    }
}
@media screen and (min-width: 576px) and (max-width: 991px) {
    .commonPL {
        padding-left: 30px !important;
    }

    .about_fertilizer--wrapper .commonPL {
        padding-left: 0px !important;
    }
}

@media screen and (max-width: 575px) {
    .commonPL {
        padding-left: 0px !important;
    }

    .about_fertilizer--wrapper .commonPL {
        padding-left: 0px !important;
    }
}